import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

export interface PasswordTextFieldProps extends Omit<OutlinedInputProps, "onChange" | "error"> {
    onChange: (value: string) => void;
    error?: Error;
}

export const PasswordTextField = (props: PasswordTextFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const { label, value, onChange, error, disabled, ...other } = props;

    return <FormControl variant="outlined" fullWidth size="small">
        <InputLabel
            sx={[Boolean(error) && { color: "#f44336" }]}
        >
            {label}
        </InputLabel>
        <OutlinedInput
            label={label}
            type={showPassword ? 'text' : 'password'}
            value={Boolean(value) ? value : ""}
            onChange={({ currentTarget: { value } }) => onChange(value)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                        size="small"
                        disabled={disabled}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }
            error={Boolean(error)}
            autoComplete="new-password"
            disabled={disabled}
            {...other}
        />
        {Boolean(error) && <FormHelperText
            sx={{
                margin: 0,
                color: "#f44336"
            }}
        >
            {error?.message}
        </FormHelperText>}
    </FormControl>
}