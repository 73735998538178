import { Button, Divider, Stack, Tab, Tabs } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import { ContextTab } from "../types";
import { ContextsTab } from "./commons/ContextsTab";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoadingButton from "@mui/lab/LoadingButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { ProfileTab } from "./commons/ProfileTab";
import SettingsIcon from "@mui/icons-material/Settings";
import { TabPanel } from "./commons/TabPanel";
import { observer } from "mobx-react";
import { useAppContext } from "../app/AppContext";

export const MainView = observer(() => {
    const store = useAppContext();

    return <Stack
        sx={{
            height: "100%",
            minWidth: "320px"
        }}
        spacing={1}
    >
        <Container
            maxWidth="sm"
            fixed
            sx={{
                padding: "16px 8px 8px !important",
                backgroundColor: "#fff"
            }}
        >
            <Tabs
                value={store.tab}
                onChange={(e, value) => store.tab = value}
                variant="fullWidth"
                color="primary"
            >
                <Tab
                    icon={<PermIdentityIcon />}
                    iconPosition="start"
                    label="Доступ"
                    sx={{
                        "&.MuiTab-root": {
                            minHeight: "48px",
                            padding: "6px 8px"
                        }
                    }}
                    color="primary"
                />
                <Tab
                    icon={<SettingsIcon />}
                    iconPosition="start"
                    label="Профиль"
                    sx={{
                        "&.MuiTab-root": {
                            minHeight: "48px",
                            padding: "6px 8px"
                        }
                    }}
                    color="primary"
                />
            </Tabs>
        </Container>
        <TabPanel
            index={ContextTab.CONTEXTS}
            value={store.tab}
        >
            <Container
                maxWidth="sm"
                fixed
                sx={{
                    padding: "8px !important",
                    flex: 1,
                    overflow: "hidden"
                }}
            >
                <ContextsTab />
            </Container>
            <Divider />
            <Container
                maxWidth="sm"
                fixed
                sx={{
                    padding: "8px 8px 16px !important"
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Button
                        onClick={store.onLogout}
                        startIcon={<ExitToAppIcon />}
                        size="small"
                        color="primary"
                    >
                        Выйти
                    </Button>
                    <LoadingButton
                        size="small"
                        disabled={!Boolean(store.selectedContext)}
                        onClick={store.onChangeContext}
                        startIcon={<ArrowForwardIcon />}
                        loading={store.isLoading}
                        variant="contained"
                        color="primary"
                    >
                        Продолжить
                    </LoadingButton>
                </Stack>
            </Container>
        </TabPanel>
        <TabPanel
            index={ContextTab.PROFILE}
            value={store.tab}
        >
            <Container
                maxWidth="sm"
                fixed
                sx={{
                    padding: "8px 8px 16px !important",
                    flex: 1,
                    overflow: "hidden"
                }}
            >
                <ProfileTab />
            </Container>
        </TabPanel>
    </Stack>
})