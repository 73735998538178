import { AuditRecord } from "../models/AuditRecord";
import { ChangePasswordRequest } from "../models/ChangePasswordRequest";
import { HttpClient } from "../rest/HttpClient";
import { LoginRequest } from "../models/LoginRequest";
import { LoginResponse } from "../models/LoginResponse";
import { RequestBase } from "../models/RequestBase";
import { ResultResponse } from "../models/ResultResponse";

export class AppRepository {
    readonly sendAuditRecord = async (auditRecord: AuditRecord) => {
        const { host, method } = window.configuration.methods.audit;
        const request: RequestBase<AuditRecord> = {
            request: auditRecord
        };

        return new HttpClient(host + method, "POST")
            .withJsonRequest(request)
            .execute();
    }

    readonly login = async (request: LoginRequest) => {
        const { host, method } = window.configuration.methods.login;

        return new HttpClient(host + method, "POST")
            .withJsonRequest(request)
            .withJsonReviver()
            .execute<LoginResponse>();
    }

    readonly logout = (tokenId: string, token: string) => {
        const { host, method } = window.configuration.methods.logout;

        return new HttpClient(host + method.replace("{tokenId}", tokenId), "GET")
            .withBearerAuthorization(token)
            .execute<ResultResponse>();
    }

    readonly changePassword = async (changePasswordRequest: ChangePasswordRequest, token: string) => {
        const { host, method } = window.configuration.methods.changePassword;
        const request: RequestBase<ChangePasswordRequest> = {
            request: changePasswordRequest
        };

        return new HttpClient(host + method, "POST")
            .withJsonRequest(request)
            .withBearerAuthorization(token)
            .withJsonReviver()
            .execute<ResultResponse>();
    }
}