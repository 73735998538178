import { Box, Container as MuiContainer, Stack } from "@mui/material";

import { CONTAINER_BGCOLOR } from "../consts";
import { Container } from "./commons/Container";
import { Footer } from "./commons/Footer";
import { Header } from "./commons/Header";
import { LoginForm } from "./forms/LoginForm";
import { observer } from "mobx-react";

import bgImg from "../img/bg_img_opacity.jpg";

export const LoginView = observer(() => {
    return <Stack
        sx={{
            height: "100%",
            minWidth: "320px"
        }}
    >
        <Container maxWidth="xs" position="top">
            <Header />
        </Container>
        <Box
            sx={{
                backgroundColor: CONTAINER_BGCOLOR,
                backgroundImage: `url(${bgImg})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                flex: 1
            }}
        >
            <MuiContainer
                maxWidth="xs"
                fixed
                sx={{
                    height: "100%",
                    padding: "16px 8px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <LoginForm />
            </MuiContainer>
        </Box>
        <Container position="bottom">
            <Footer />
        </Container>
    </Stack>
})