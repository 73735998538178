import {Box, Stack, Typography} from "@mui/material";

import header from "../../svg/logo_nv_header_new.svg";
import {useAppContext} from "../../app/AppContext";
import {AppState} from "../../types";

export const Header = () => {
    const store = useAppContext();

    return <Stack
        sx={theme => ({
            width: "100%",
            overflow: "hidden",
            paddingBottom: "16px",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down('sm')]: {
                justifyContent: "start"
            }
        })}
    >
        <img height={56} src={header} alt="" />
        {(store.appState === AppState.SELECT_GROUP || store.appState === AppState.SELECT_ROLE)
            &&
            <Box sx={{
                justifyContent: "flex-end",
                position: "absolute",
                right: "3%"
            }}>
                <Typography sx={{
                    color: '#303030',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '137.688%',
                    cursor: "pointer",
                    textDecoration: 'none',
                    '&:hover': {textDecoration: 'underline'}
                }}
                            onClick={store.onLogout}>
                    Выйти
                </Typography>
            </Box>
        }
    </Stack>
}