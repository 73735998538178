export enum AppState {
    LOGIN = 0,
    CHANGE_CONTEXT = 1,
    CHANGE_PASSWORD = 2,
    SUCCESS = 3,
    SELECT_GROUP = 4,
    SELECT_ROLE = 5
}

export type Nullable<T> = T | null;

export const ContextTab = {
    CONTEXTS: 0,
    PROFILE: 1
}


export type ChangePasswordFormStateType = {
    showPassword: boolean,
    oldPassword: string,
    isOldPasswordValid: boolean,
    newPassword: string,
    isNewPasswordValid: boolean,
    repeatNewPassword: string,
    isRepeatNewPasswordValid: boolean,
    isFormValid: boolean,
    isError: boolean,
    errorReason: string,
    isLoading: boolean
};

export type GroupType = {
    idx: number,
    groupName: string,
    processedName: string,
    processedDescription: string
}

