import { Breakpoint, Container as MuiContainer } from "@mui/material";

import { PropsWithChildren } from "react";

interface BottomContainerProps {
    maxWidth?: Breakpoint | false;
    position: "bottom" | "top"
}

export const Container = (props: PropsWithChildren<BottomContainerProps>) => {
    return <MuiContainer
        maxWidth={props.maxWidth}
        fixed
        sx={{
            padding: props.position === "bottom"
                ? "8px 8px 16px !important"
                : props.position === "top"
                    ? "16px 8px 8px !important"
                    : "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}
    >
        {props.children}
    </MuiContainer>
}