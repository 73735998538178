import { Button, Paper, Stack, TextField, Typography } from "@mui/material";

import { AppState } from "../../types";
import MuiPhoneNumber from "material-ui-phone-number";
import PasswordIcon from "@mui/icons-material/Password";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { useAppContext } from "../../app/AppContext";

export const ProfileTab = observer(() => {
    const store = useAppContext();

    return <Paper
        elevation={4}
        sx={{
            p: 1,
            boxSizing: "border-box",
            height: "100%"
        }}
    >
        <Stack
            spacing={1}
        >
            <Button
                variant="contained"
                onClick={() => store.appState = AppState.CHANGE_PASSWORD}
                startIcon={<PasswordIcon />}
                size="small"
                sx={{
                    alignSelf: "flex-start"
                }}
                color="primary"
            >
                Изменить пароль
            </Button>
            <Typography
                variant="h6"
                color="primary"
            >
                Общие сведения
            </Typography>
            <TextField
                label="Логин"
                value={store.user.login || ""}
                size="small"
                disabled
                variant="outlined"
                color="primary"
            />
            <TextField
                label="ФИО"
                value={store.userFullName}
                size="small"
                disabled
                variant="outlined"
                color="primary"
            />
            <Typography
                variant="h6"
                color="primary"
            >
                Контактная информация
            </Typography>
            <TextField
                label="Эл. почта"
                value={store.user.email || ""}
                size="small"
                disabled
                variant="outlined"
                color="primary"
            />
            <MuiPhoneNumber
                label="Телефон"
                value={store.user.phone || ""}
                onChange={() => { }}
                defaultCountry={"ru"}
                onlyCountries={["ru"]}
                variant="outlined"
                fullWidth
                size="small"
                countryCodeEditable={false}
                disabled
                color="primary"
            />
            <Typography
                variant="h6"
                color="primary"
            >
                Аудит
            </Typography>
            <TextField
                label="Последний вход"
                value={dayjs(store.lastLoginTime).format("DD.MM.YYYY HH:mm:ss")}
                size="small"
                disabled
                variant="outlined"
                color="primary"
            />
        </Stack>
    </Paper>
})