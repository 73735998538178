import "./index.css";

import { App } from "./App";
import { AppContextProvider } from "./app/AppContextProvider";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { configure } from "mobx";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./themes";

configure({
  enforceActions: "never",
});

dayjs.locale("ru");
dayjs.extend(localizedFormat);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<ThemeProvider theme={theme}>
  <AppContextProvider>
    <App />
  </AppContextProvider>
</ThemeProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();