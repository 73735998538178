import { Box } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;

    if (value !== index) {
        return null;
    }

    return <Box
        sx={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column"
        }}
    >
        {children}
    </Box>;
}