import { ChangePasswordView } from "./ui/ChangePasswordView";
import { LoginView } from "./ui/LoginView";
import { MainView } from "./ui/MainView";
import { Snackbar } from "./ui/commons/Snackbar";
import { SuccessView } from "./ui/SuccessView";
import { observer } from "mobx-react";
import { useAppContext } from "./app/AppContext";
import {SelectGroupView} from "./ui/SelectGroupView";
import {SelectRoleView} from "./ui/SelectRoleView";

export const App = observer(() => {
  const store = useAppContext();
  
  const view = [
    <LoginView />,
    <MainView />,
    <ChangePasswordView />,
    <SuccessView />,
    <SelectGroupView/>,
    <SelectRoleView/>
  ][store.appState];

  return <>
    <Snackbar
      open={Boolean(store.success)}
      severity="success"
      message={store.success}
      onClose={() => store.success = null}
    />
    <Snackbar
      open={Boolean(store.error)}
      severity="error"
      message={store.error?.message || ""}
      onClose={() => store.error = null}
    />
    {view}
  </>;
})

