import {observer} from "mobx-react";
import {Avatar, Box, Button, styled, Typography} from "@mui/material";
import {useAppContext} from "../../app/AppContext";

export const RoleSelectionForm = observer(() => {
    const store = useAppContext();

    const StyledButton = styled(Button)(({theme, disabled}) => ({
        marginTop: '10px',
        display: 'flex',
        height: '39px',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        flexShrink: 0,
        borderRadius: '5px',
        background: disabled ? '#DEDEDE' : '#4780A6',
        transition: 'background-color 0.3s ease-in-out',
        '&:hover': {
            background: disabled ? '#DEDEDE' : '#426F9B',
        },
    }));

    return (
        <Box sx={{
            width: "30vw",
            bgcolor: "#FFFFFF",
            border: "1px solid #F0F0F0",
            padding: "24px 16px 12px 16px",
            borderRadius: "8px"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: 'center'
            }}>
                <Avatar sx={{
                    width: "80px",
                    height: "80px"
                }}>

                </Avatar>
                <Box sx={{
                    marginLeft: "20px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left"
                }}>
                    <Typography sx={{
                        color: '#0D0D0D',
                        fontFamily: 'Roboto',
                        fontSize: 24,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '137.688%',
                    }}>
                        {store.contextGroups.find(group => group.groupIdx === store.group)?.groupName || ''}
                    </Typography>
                    <Typography sx={{
                        color: '#A5A5A5',
                        fontFamily: 'Roboto',
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '137.688%',
                    }}>
                        {''}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{
                marginTop: "8px",
                paddingTop: "18px"
            }}>
                <Typography sx={{
                    color: '#0D0D0D',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '137.688%'
                }}>
                    Выберите роль
                </Typography>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column"
                }}>

                    {
                        store.groupedContexts[store.contextGroups[store.group].groupName].map(context => {
                            let label = `${context.position}`.trim()
                            if (label.endsWith(",")) {
                                label = label.slice(0, label.length - 1);
                            }
                            const contextStringify = JSON.stringify(context);

                            return <Box
                                sx={{
                                    display: 'flex',
                                    padding: '12px 18px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px',
                                    borderRadius: '5px',
                                    border: '1px solid #4780A6',
                                    marginTop: '10px',
                                    cursor: 'pointer',
                                    transition: 'border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
                                    backgroundColor: store.selectedContext === contextStringify ? '#DFECF4' : 'transparent',
                                    '&:hover': {
                                        borderColor: store.selectedContext === contextStringify ? '#4780A6' : '#EDEDED',
                                    }
                                }
                                }
                                key={contextStringify}
                                onClick={() => store.selectedContext = store.selectedContext === contextStringify ? "" : contextStringify}>
                                <Typography sx={{
                                    color: '#4780A6',
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal'
                                }}>
                                    {label}
                                </Typography>
                            </Box>
                        })
                    }
                </Box>

                <StyledButton disabled={store.selectedContext.trim() === ""}
                              onClick={store.onChangeContext}>
                    <Typography sx={{
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                    }}>
                        Войти
                    </Typography>
                </StyledButton>
            </Box>
        </Box>
    )
})