import { Alert, Stack, Typography } from "@mui/material";

import { ChangePasswordViewStore } from "../ChangePasswordViewStore";
import { Paper } from "@mui/material";
import { PasswordTextField } from "../commons/PasswordTextField";
import { observer } from "mobx-react";

interface ChangePasswordFormProps {
    store: ChangePasswordViewStore;
}

export const ChangePasswordForm = observer((props: ChangePasswordFormProps) => {
    const { store } = props;

    return <Stack
        spacing={1}
        sx={{
            height: "100%"
        }}
    >
        <Typography
            variant="h4"
            align="center"
            color="primary"
        >
            Смена пароля
        </Typography>
        <Paper
            elevation={4}
            sx={{
                p: 1,
                height: "100%",
                boxSizing: "border-box"
            }}
        >
            <Stack
                spacing={1}
            >
                <PasswordTextField
                    label="Текущий пароль"
                    value={store.current}
                    onChange={value => store.current = value}
                    error={store.isValidCurrent}
                    disabled={store.isLoading}
                    color="primary"
                />
                <Alert severity="info" icon={false}>
                    <Typography variant="caption">
                        Пароль должен содержать прописные и строчные буквы латинского алфавита, хотя бы одну цифру, один специальный символ: %, *, (, ), ?, @, #, $, ~, !, + и быть в длину от 8 символов. Новый пароль не должен совпадать со старым.
                    </Typography>
                </Alert>
                <PasswordTextField
                    label="Новый пароль"
                    value={store.new}
                    onChange={value => store.new = value}
                    error={store.isValidNew}
                    disabled={store.isLoading}
                    color="primary"
                />
                <PasswordTextField
                    label="Повтор"
                    value={store.repeat}
                    onChange={value => store.repeat = value}
                    error={store.isValidRepeat}
                    disabled={store.isLoading}
                    color="primary"
                />
            </Stack>
        </Paper>
    </Stack>;
})