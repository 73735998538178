import {Box, Stack, Typography} from "@mui/material";

import footer from "../../img/logo-footer-new.png";

export const Footer = () => {
    return <Stack
        direction="row"
        spacing={1}
        sx={theme => ({
          width: "100%",
          paddingTop: "8px",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.down('sm')]: {
              justifyContent: "start"
          }
        })}
    >
        <Box sx={{
            justifyContent: "center",
        }}>
            <img height={26} src={footer} alt="" />
        </Box>
        <Box sx={{
            flexDirection: "column",
            justifyContent: "flex-end",
            position: "absolute",
            right: "3%"
        }}>
            <Typography sx={{
                color: "#9C9898",
                fontSize: "11px",
                fontWeight: "400"
            }}>
                Техподдержка
            </Typography>
            <Typography sx={{
                color: "#1C1B1B",
                fontSize: "14px",
                fontWeight: "400"
            }}>
                support@inschooltech.ru
            </Typography>
        </Box>

    </Stack>;
}