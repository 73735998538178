import { Box, Button, Container, Divider, Stack } from "@mui/material";

import { ChangePasswordForm } from "./forms/ChangePasswordForm";
import { ChangePasswordViewStore } from "./ChangePasswordViewStore";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoadingButton from "@mui/lab/LoadingButton";
import { PRIMARY_COLOR_MAIN } from "../consts";
import SaveIcon from "@mui/icons-material/Save";
import { observer } from "mobx-react";
import { useAppContext } from "../app/AppContext";
import { useMemo } from "react";

export const ChangePasswordView = observer(() => {
    const appStore = useAppContext();
    const store = useMemo(() => new ChangePasswordViewStore(appStore), []);

    return <Box
        sx={{
            height: "100%",
            minWidth: "320px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column"
        }}
    >
        <Container
            maxWidth="sm"
            fixed
            sx={{
                padding: "16px 8px 8px !important",
                flex: 1,
                overflow: "hidden auto",
                "&::-webkit-scrollbar": {
                    display: "none"
                }
            }}
        >
            <ChangePasswordForm store={store} />
        </Container>
        <Divider />
        <Container
            maxWidth="sm"
            fixed
            sx={{
                padding: "8px 8px 16px !important"
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <Button
                    disabled={store.isBackBtnDisabled || store.isLoading}
                    onClick={store.onBack}
                    startIcon={<ExitToAppIcon />}
                    size="small"
                    sx={{
                        "&.MuiButton-root": {
                            color: PRIMARY_COLOR_MAIN
                        },
                        "&.MuiButton-root.Mui-disabled": {
                            color: "rgba(0, 0, 0, 0.26)",
                            boxShadow: "none"
                        }
                    }}
                >
                    Назад
                </Button>
                <LoadingButton
                    size="small"
                    disabled={store.isChangePasswordBtnDisabled}
                    onClick={store.changePassword}
                    startIcon={<SaveIcon />}
                    loading={store.isLoading}
                    variant="contained"
                    sx={{
                        "&.MuiButton-root": {
                            backgroundColor: PRIMARY_COLOR_MAIN,
                            color: "#fff"
                        },
                        "&.MuiButton-root.Mui-disabled": {
                            color: "rgba(0, 0, 0, 0.26)",
                            boxShadow: "none",
                            backgroundColor: "rgba(0, 0, 0, 0.12)"
                        },
                        "& > .MuiLoadingButton-loadingIndicator": {
                            color: "#fff"
                        }
                    }}
                >
                    Сохранить
                </LoadingButton>
            </Stack>
        </Container>
    </Box>
})