import { Box, Container as MuiContainer, Stack, Typography } from "@mui/material";

import { CONTAINER_BGCOLOR } from "../consts";
import { Container } from "./commons/Container";
import { Footer } from "./commons/Footer";
import { Header } from "./commons/Header";

import bgImg from "../img/bg_img_opacity.jpg";

export const SuccessView = () => {
    return <Stack
        sx={{
            height: "100%",
            minWidth: "400px"
        }}
    >
        <Container maxWidth="sm" position="top">
            <Header />
        </Container>
        <Box
            sx={{
                backgroundColor: CONTAINER_BGCOLOR,
                backgroundImage: `url(${bgImg})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                flex: 1
            }}
        >
            <MuiContainer
                maxWidth="sm"
                fixed
                sx={{
                    padding: "8px !important",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%"
                }}
            >
                <Typography
                    variant="h4"
                    align="center"
                    color="primary"
                >
                    Выполняется переход
                </Typography>
                <Typography
                    align="center"
                    color="primary"
                >
                    Не закрывайте эту вкладку
                </Typography>
            </MuiContainer>
        </Box>
        <Container maxWidth="sm" position="bottom">
            <Footer />
        </Container>
    </Stack>
};