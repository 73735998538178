import {observer} from "mobx-react";
import {Container} from "./commons/Container";
import {Header} from "./commons/Header";
import {Box, Container as MuiContainer, Stack} from "@mui/material";
import {CONTAINER_BGCOLOR} from "../consts";
import bgImg from "../img/bg_img_opacity.jpg";
import {Footer} from "./commons/Footer";
import {RoleSelectionForm} from "./commons/RoleSelectionForm";

export const SelectRoleView = observer(() => {
  return (
      <Stack sx={{
        height: "100%",
        minWidth: "320px"
      }}>
        <Container maxWidth="xs" position="top">
          <Header />
        </Container>
        <Box
            sx={{
              backgroundColor: CONTAINER_BGCOLOR,
              backgroundImage: `url(${bgImg})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              flex: 1
            }}
        >
          <MuiContainer
              maxWidth="md"
              fixed
              sx={{
                height: "100%",
                padding: "16px 8px !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
          >
            <RoleSelectionForm/>
          </MuiContainer>
        </Box>
        <Container position="bottom">
          <Footer />
        </Container>
      </Stack>
  )
})