import { AppState, Nullable } from "../types";
import { makeObservable, observable } from "mobx";

import { AppStore } from "../app/AppStore";

export class ChangePasswordViewStore {
    private _current: Nullable<string>;
    private _new: Nullable<string>;
    private _repeat: Nullable<string>;

    constructor(private _appStore: AppStore) {

        this._current = null;
        this._new = null;
        this._repeat = null;

        makeObservable<ChangePasswordViewStore, "_current" | "_new" | "_repeat">(this, {
            _current: observable,
            _new: observable,
            _repeat: observable
        });
    }

    private _isPasswordInvalid = (password: string): boolean => {
        if (password === "") {
            return true;
        }

        // новый пароль не совпадает со старым
        if (password === this._appStore.password) {
            return true;
        }

        // хотя бы одна прописная латинская буква
        if (!/[A-Z]+/.test(password)) {
            return true;
        }

        // хотя бы одна строчная латинская буква
        if (!/[a-z]+/.test(password)) {
            return true;
        }

        // хотя бы одна цифра
        if (!/[0-9]+/.test(password)) {
            return true;
        }

        // хотя бы один специальный символ из %, *, (, ), ?, @, #, $, ~, !, + 
        if (!/[%, *, (, ), ?, @, #, $, ~, !, +]+/.test(password)) {
            return true;
        }

        // длинная пароля от 8 символов
        if (!(password.length >= 8)) {
            return true;
        }

        return false;
    }

    get current() {
        return this._current ?? "";
    }

    set current(value: string) {
        this._current = value.trim() === "" ? null : value;
    }

    get new() {
        return this._new ?? "";
    }

    set new(value: string) {
        this._new = value.trim() === "" ? null : value;
    }

    get repeat() {
        return this._repeat ?? "";
    }

    set repeat(value: string) {
        this._repeat = value.trim() === "" ? null : value;
    }

    get isValidCurrent(): Error | undefined {
        if (this._current === null && this._new === null && this._repeat === null) {
            return undefined;
        }

        if (this._current === null) {
            return new Error("Не может быть пустым");
        }

        if (this.current !== this._appStore.password) {
            return new Error("Неверный пароль");
        }

        return undefined;
    }

    get isValidNew(): Error | undefined {
        if (this._current === null && this._new === null && this._repeat === null) {
            return undefined;
        }

        if (this._new === null) {
            return new Error("Не может быть пустым");
        }

        if (this._isPasswordInvalid(this._new)) {
            return new Error("Не соответствует требованиям политики безопасности формирования пароля");
        }

        return undefined;
    }

    get isValidRepeat(): Error | undefined {
        if (this._current === null && this._new === null && this._repeat === null) {
            return undefined;
        }

        if (this._new !== this._repeat) {
            return new Error("Новый пароль и повтор не совпадают");
        }

        return undefined;
    }

    changePassword = async () => {
        const result = await this._appStore.onChangePassword({
            id: this._appStore.user.userId,
            oldPassword: this.current,
            newPassword: this.new
        });
        if (result) {
            this._clearFields();
        }
    }

    private _clearFields = () => {
        this._current = this._new = this._repeat = null;
    }

    get isChangePasswordBtnDisabled() {
        return this._current == null || this._new == null || this._repeat == null
            || Boolean(this.isValidCurrent) || Boolean(this.isValidNew) || Boolean(this.isValidRepeat);
    }

    onBack = () => {
        this._appStore.appState = AppState.LOGIN;
        this._clearFields();
    }

    get isBackBtnDisabled() {
        return this._appStore.needPasswordChange;
    }

    get isLoading() {
        return this._appStore.isLoading;
    }
}