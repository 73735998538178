import { Box, Checkbox, FormControlLabel, FormGroup, Paper, Tab, Tabs } from "@mui/material";

import { observer } from "mobx-react";
import { useAppContext } from "../../app/AppContext";

export const ContextsTab = observer(() => {
  const store = useAppContext();
  return <Paper
    elevation={4}
    sx={{
      p: 1,
      boxSizing: "border-box",
      display: "flex",
      height: "100%"
    }}
  >
    <Tabs
      orientation="vertical"
      value={store.group}
      onChange={(e, value) => store.onChangeGroup(value)}
      color="primary"
      sx={{
        flex: 1
      }}
      variant="scrollable"
    >
      {store.contextGroups.map(group => {
        return <Tab
          key={group.groupIdx}
          label={group.groupName}
          color="primary"
          sx={{
            "&.MuiTab-root": {
              padding: "6px 8px",
              textAlign: "left",
              alignItems: "flex-start"
            }
          }}
        />
      })}
    </Tabs>
    <Box
      sx={{
        flex: 1,
        borderLeft: 1,
        borderColor: "divider",
        overflow: "auto"
      }}
    >
      <FormGroup>
        {store.groupedContexts[store.contextGroups[store.group].groupName].map(context => {
          let label = `${context.position}`.trim()
          if (label.endsWith(",")) {
            label = label.slice(0, label.length - 1);
          }
          const contextStringify = JSON.stringify(context);
          return <FormControlLabel
            key={label}
            control={<Checkbox
              size="small"
              checked={store.selectedContext === contextStringify}
              onChange={() => store.selectedContext = store.selectedContext === contextStringify ? "" : contextStringify}
            />}
            label={label}
            sx={{
              padding: "6px 8px",
              "&.MuiFormControlLabel-root": {
                marginLeft: 0
              }
            }}
          />
        })}
      </FormGroup>
    </Box>
  </Paper>
})