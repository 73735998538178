import {AppState, ContextTab, Nullable} from "../types"

import { AppRepository } from "./AppRepository";
import { AuditRecord } from "../models/AuditRecord";
import { ChangePasswordRequest } from "../models/ChangePasswordRequest";
import { Context } from "../models/Context"
import DeviceDetector from "device-detector-js";
import { JwtToken } from "../models/JwtToken";
import { ReactNode } from "react";
import { User } from "../models/User";
import _ from "underscore";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { makeAutoObservable } from "mobx";

interface IAppStore {
    appState: AppState;
    login: string;
    password: string;
    contexts: Context[];
    lastLoginTime: Nullable<Date>;
    tokenId: string;
    token: string;
    needPasswordChange: boolean;
    isThirdPartyComputer: boolean;
    user: User;
    tab: number;
    group: number;
    selectedContext: string;
    error: Nullable<Error>;
    success: Nullable<ReactNode>;
    isLoading: boolean;
}

export class AppStore implements IAppStore {
    appState: AppState = AppState.LOGIN;
    login: string = localStorage.getItem("ms-clinic-login") || "";
    password: string = localStorage.getItem("ms-clinic-pwd") || "";
    contexts: Context[] = [];
    lastLoginTime: Nullable<Date> = null;
    tokenId: string = "";
    token: string = "";
    needPasswordChange: boolean = false;
    isThirdPartyComputer: boolean = false;
    user: User = null!;
    tab: number = ContextTab.CONTEXTS;
    group: number = 0;
    selectedContext: string = "";

    error: Nullable<Error> = null;
    success: Nullable<ReactNode> = null;
    isLoading: boolean = false;

    private _defaults = ({
        appState = AppState.LOGIN,
        login = localStorage.getItem("ms-clinic-login") || "",
        password = localStorage.getItem("ms-clinic-pwd") || "",
        contexts = [],
        lastLoginTime = null,
        tokenId = "",
        token = "",
        needPasswordChange = false,
        isThirdPartyComputer = false,
        user = null!,
        tab = ContextTab.CONTEXTS,
        group: panel = 0,
        selectedContext = "",
    }: Partial<Omit<IAppStore, "success" | "error" | "isLoading">> = {}
    ) => {
        this.appState = appState;
        this.login = login;
        this.password = password;
        this.contexts = contexts;
        this.lastLoginTime = lastLoginTime;
        this.tokenId = tokenId;
        this.token = token;
        this.needPasswordChange = needPasswordChange;
        this.isThirdPartyComputer = isThirdPartyComputer;
        this.user = user;
        this.tab = tab;
        this.group = panel;
        this.selectedContext = selectedContext;
    }

    constructor() {
        makeAutoObservable(this);
    }

    execute = async <T>(action: () => Promise<T>) => {
        try {
            this.isLoading = true;
            await action();
        } catch (error) {
            this.error = axios.isAxiosError(error)
                ? new Error(error.message)
                : typeof error === "string"
                    ? new Error(error)
                    : error as Error;
        } finally {
            this.isLoading = false;
        }
    }

    private _decodeJwtToken = (token: string) => jwt_decode<JwtToken>(token);

    private _getUserFromJwtToken = (token: string) => {
        const decodedToken = this._decodeJwtToken(token);
        return JSON.parse(decodedToken.userInfo) as User;
    }

    onLogin = async () => {
        this.execute(async () => {
            const appRepository = new AppRepository();
            const deviceDetector = new DeviceDetector();
            const userAgent = deviceDetector.parse(window.navigator.userAgent);

            const auditRecord: AuditRecord = {
                operationName: "",
                objectTypeName: "Пользователь",
                created: new Date(),
                login: this.login,
                userAgent: JSON.stringify(userAgent)
            };

            const loginResponse = await appRepository.login({ login: this.login, password: this.password });
            if (!loginResponse.result.success) {
                auditRecord.operationName = "Попытка входа в систему";
                await appRepository.sendAuditRecord(auditRecord);
                this.error = new Error("Неверный логин или пароль.");
                return;
            }

            this.lastLoginTime = loginResponse.lastLoginTime;
            this.contexts = loginResponse.contexts;
            this.tokenId = loginResponse.tokenId;
            this.token = loginResponse.token;
            this.needPasswordChange = loginResponse.needPasswordChange;

            this.user = this._getUserFromJwtToken(loginResponse.token);

            auditRecord.userFullName = this.userFullName;
            auditRecord.operationName = "Вход в систему";
            await appRepository.sendAuditRecord(auditRecord);

            if (!this.isThirdPartyComputer) {
                localStorage.setItem("ms-clinic-login", this.login);
                localStorage.setItem("ms-clinic-pwd", this.password);
            } else {
                localStorage.removeItem("ms-clinic-login");
                localStorage.removeItem("ms-clinic-pwd");
            }

            this.appState = this.needPasswordChange ? AppState.CHANGE_PASSWORD : AppState.SELECT_GROUP;
        });
    }

/*    onForgotPassword = () => {

    }*/

    onLogout = async () => {
        await this.execute(async () => {
            const appRepository = new AppRepository();
            await appRepository.logout(this.tokenId, this.token);
        });
        this._defaults({ appState: AppState.LOGIN });
    }

    onChangeContext = async () => {
        this.execute(async () => {
            if (this.decodedSelectedContext === null) {
                return;
            }

            const { redirect, immRoleId } = this.decodedSelectedContext;

            const appRepository = new AppRepository();
            const deviceDetector = new DeviceDetector();
            const userAgent = deviceDetector.parse(window.navigator.userAgent);

            const auditRecord: AuditRecord = {
                userFullName: this.userFullName,
                operationName: "",
                objectTypeName: "Пользователь",
                created: new Date(),
                login: this.login,
                userAgent: JSON.stringify(userAgent)
            };

            if (Boolean(redirect.url)) {
                auditRecord.operationName = `Переход в web-приложение (${redirect.url})`;
                await appRepository.sendAuditRecord(auditRecord);
                window.location.assign(redirect.url.replace("{tokenId}", this.tokenId).replace("{roleId}", immRoleId));
                this._defaults({ appState: AppState.SUCCESS });
                return;
            }

            this.error = new Error("Невозможно выполнить переход в web-приложение, не настроен роутинг...");
        })
    }

    onChangePassword = async (request: ChangePasswordRequest) => {
        let result = false;
        await this.execute(async () => {
            const appRepository = new AppRepository();
            const response = await appRepository.changePassword(request, this.token);
            if (response.result.success) {
                this.success = "Пароль успешно изменен! Необходимо выполнить повторный вход!";
                this._defaults();
                result = true;
            }
        });

        return result;
    }

    onChangeGroup = (group: number) => {
        this.group = group;
        this.selectedContext = "";
        if (this.groupedContexts[this.contextGroups[this.group].groupName].length === 1) {
            let pos = this.groupedContexts[this.contextGroups[this.group].groupName][0];

            const contextStringify = JSON.stringify(pos);
            this.selectedContext = this.selectedContext === contextStringify ? "" : contextStringify
            this.onChangeContext();
        } else {
            this.appState = AppState.SELECT_ROLE;
        }
    }

    get userFullName() {
        if (!this.user) {
            return "";
        }

        return `${this.user.lastName?.trim() || ""} ${!this.user.firstName?.trim() ? "" : `${this.user.firstName?.trim()[0]}.`}${!this.user.middleName?.trim() ? "" : `${this.user.middleName?.trim()[0]}.`}`;
    }

    get groupedContexts() {
        return _.groupBy(this.contexts, i => i.lpu);
    }

    get decodedSelectedContext() {
        return Boolean(this.selectedContext) ? JSON.parse(this.selectedContext) as Context : null;
    }

    get contextGroups() {
        const result: Array<{
            groupName: string;
            groupIdx: number;
            processedName: string;
            processedDescription: string;
        }> = [];
        _.sortBy(Object.keys(this.groupedContexts)).map((group, idx) => {
            let processedData = this.processGroupsResponse(group);
            result.push({
                groupName: group !== "null" ? group : "",
                groupIdx: idx,
                processedName: processedData.processedName,
                processedDescription: processedData.processedDescription
            });
        });
        return result;
    }

    private processGroupsResponse = (groupName: string) => {
        switch (groupName) {
            case ('Школа') : return {
                processedName: "Образовательная платформа",
                processedDescription: "Инскултех"
            }
            default : return {
                processedName: groupName,
                processedDescription: ""
            }
        }
    }
}
