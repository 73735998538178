import {observer} from "mobx-react";
import {Box, Container as MuiContainer, Stack} from "@mui/material";
import {Container} from "./commons/Container";
import {Header} from "./commons/Header";
import {CONTAINER_BGCOLOR} from "../consts";
import bgImg from "../img/bg_img_opacity.jpg";
import {Footer} from "./commons/Footer";
import {useAppContext} from "../app/AppContext";
import {GroupSelectionCard} from "./commons/GroupSelectionCard";

export const SelectGroupView = observer(() => {
    const store = useAppContext();

    return (
        <Stack sx={{
            height: "100%",
            minWidth: "320px"
        }}>
            <Container maxWidth="xs" position="top">
                <Header />
            </Container>
            <Box
                sx={{
                    backgroundColor: CONTAINER_BGCOLOR,
                    backgroundImage: `url(${bgImg})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    flex: 1
                }}
            >
                <MuiContainer
                    maxWidth="md"
                    fixed
                    sx={{
                        height: "100%",
                        padding: "16px 8px !important",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {
                        store.contextGroups.map(group => {
                            return <GroupSelectionCard idx={group.groupIdx}
                                                       groupName={group.groupName}
                                                       processedName={group.processedName}
                                                       processedDescription={group.processedDescription}/>
                        })
                    }
                </MuiContainer>
            </Box>
            <Container position="bottom">
                <Footer />
            </Container>
        </Stack>
    )
});