import { PropsWithChildren, useMemo } from "react";

import { AppContext } from "./AppContext";
import { AppStore } from "./AppStore";

export const AppContextProvider = (props: PropsWithChildren) => {
    const store = useMemo(() => new AppStore(), []);

    return <AppContext.Provider value={store}>
        {props.children}
    </AppContext.Provider>
}