import {Box, Checkbox, FormControlLabel, Stack, TextField, Typography} from "@mui/material";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from "@mui/lab/LoadingButton";
import { PasswordTextField } from "../commons/PasswordTextField";
import { observer } from "mobx-react";
import { useAppContext } from "../../app/AppContext";

export const LoginForm = observer(() => {
    const store = useAppContext();
    return <Stack
        spacing={1}
        sx={{
            width: "100%"
        }}
    >
        <Typography
            variant="h4"
            align="center"
            color="primary"
        >
            Вход
        </Typography>
        <TextField
            id="login"
            label="Логин"
            variant="outlined"
            size="small"
            fullWidth
            value={store.login}
            onChange={({ target: { value } }) => store.login = value}
            autoComplete="new-password"
            disabled={store.isLoading}
            color="primary"
        />
        <PasswordTextField
            label="Пароль"
            value={store.password}
            onChange={value => store.password = value}
            disabled={store.isLoading}
            color="primary"
        />
        <Box display="flex" alignItems="center" justifyContent="SPACE-BETWEEN">
            <FormControlLabel
                value="end"
                control={<Checkbox
                    color="primary"
                    size="small"
                    disabled={store.isLoading}
                />}
                label={<span style={{color: '#4780A6'}}>Чужой компьютер</span>}
                labelPlacement="end"
                onChange={(e, checked) => store.isThirdPartyComputer = checked}
                color="primary"
            />
{/*            <Typography sx={{
                color: '#4780A6',
                cursor: "pointer",
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
            }}
            onClick={() => store.onForgotPassword}>
                Забыли пароль?
            </Typography>*/}
        </Box>
        <LoadingButton
            size="small"
            onClick={store.onLogin}
            startIcon={<ArrowForwardIcon />}
            loading={store.isLoading}
            variant="contained"
            color="primary"
        >
            Войти
        </LoadingButton>
    </Stack>
})