import {Card, CardActions, CardContent, IconButton, Typography} from "@mui/material";
import ArrowForwardIcon from '../../img/select-service-arrow-next.png';
import {GroupType} from "../../types";
import {observer} from "mobx-react";
import {useAppContext} from "../../app/AppContext";
import EducationPortalSVG from "../../svg/group-label/education_platform.svg"

export const GroupSelectionCard = observer((group: GroupType) => {
    const store = useAppContext();

    function getImage() {
        switch (group.groupName) {
            case ("Школа") : return EducationPortalSVG
            default : return ""
        }
    }

    return (
        <Card sx={{
            height: 104,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingRight: "24px",
            borderRadius: "8px",
            boxShadow: "0px 11px 11px 0px rgba(0, 0, 0, 0.12)",
            border: "1px solid #F0F0F0",
            background: "#FFF",
            cursor: "pointer",
            '&:hover': { borderColor: '#4780A6' }
        }}
        onClick={() => store.onChangeGroup(group.idx)}
        key={group.idx}>

               <img src={getImage()} alt=""/>
            <CardContent sx={{
                flex: 1,
                marginLeft: "20px",
                display: "flex",
                flexDirection: "column",
                textAlign: "left"
            }}>
                <Typography sx={theme => ({
                    color: '#0D0D0D',
                    fontFamily: 'Roboto',
                    fontSize: 24,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '137.688%',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 18,
                    }
                })}>
                    {group.processedName}
                </Typography>
                <Typography sx={theme => ({
                    color: '#A5A5A5',
                    fontFamily: 'Roboto',
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '137.688%',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                    }
                })}>
                    {group.processedDescription}
                </Typography>
            </CardContent>
            <CardActions sx={theme => ({
                marginLeft: "20px",
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            })}>
                <IconButton>
                    <img height={49} src={ArrowForwardIcon} alt="" />
                </IconButton>
            </CardActions>
        </Card>
    );
});