import { Alert, AlertColor, Snackbar as MuiSnackbar, SnackbarCloseReason } from '@mui/material';
import React, { ReactNode } from 'react';

interface SnackbarProps {
    open: boolean;
    severity: AlertColor;
    message: ReactNode;
    onClose?: VoidFunction;
}

export const Snackbar = (props: SnackbarProps) => {

    const onClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.onClose && props.onClose();
    };

    return <MuiSnackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
    >
        <Alert
            variant="filled"
            elevation={6}
            severity={props.severity}
            onClose={onClose}
        >
            {props.message}
        </Alert>
    </MuiSnackbar>
}